import { gql } from '@apollo/client';

import type { TCodifiedName, TCurrencyCode, TImage } from '@/infra/types/common';

export type TCabinPrice = {
  amount: number;
  currencyCode: TCurrencyCode;
  discount: number;
  originalAmount: number;
};

export enum AgeCategoryEnum {
  'ADULT' = 'ADULT',
  'CHILD' = 'CHILD',
}

type TCabinCapacityInfo = {
  maxOccupancy?: `${number}-${number}`;
};

type TAvailability = {
  isAvailable?: boolean;
};

export type TCabinCategoryPrice = {
  fareClass: string;
  subCategoryCode: string;
  totalPrice: TCabinPrice;
};

export type TFareClassContents = {
  attributes: string[];
  fareClass: string;
  totalPrice: TCabinPrice;
};

export type TCabinCategoryCabinType = TCodifiedName & {
  balconySize: string;
  description: string;
  fareClassContents: TFareClassContents[];
  id: string | number;
  isVip: boolean;
  longDescription: string;
  lowestAvailablePrice: TCabinCategoryPrice;
  navigationOrder: `${number}`;
  roomSize: string;
  upsellDescription: string;
  upsoldTo: string[];
};

export type TCabinCategoryCabinService = {
  icon: TImage;
  name: string;
};

export type TCabinCategorySubMeta = TCabinCapacityInfo &
  TAvailability &
  TCodifiedName & {
    attributes: string[];
    balconySize: string;
    bannerMessage: string;
    cabinServices: TCabinCategoryCabinService[];
    cabinTypes: TCabinCategoryCabinType[];
    capacityImage: TImage;
    description: string;
    fareClassContents: TFareClassContents[];
    floorplan: TImage;
    headerImage: TImage;
    id: string | number;
    isVip: boolean;
    landscapeGallery: TImage[];
    longDescription: string;
    lowestAvailablePrice: TCabinCategoryPrice;
    maxDiscount: boolean | string;
    navigationOrder: `${number}`;
    recirculationMessage: string;
    roomSize: string;
    shipZoneImage: TImage;
    smallThumbnail: TImage;
    title: string;
    upsellDescription: string;
    upsoldTo: string[];
  };

export type TCabinIncludedItem = {
  icon: TImage;
  text: string;
};

export type TCabinCategory = TCabinCapacityInfo &
  TAvailability &
  TCodifiedName & {
    amenities: string[];
    attributes: string[];
    description: string;
    isVip: boolean;
    landscapeGallery: TImage[];
    longDescription: string;
    lowestSubCategoryPrice: TCabinCategoryPrice;
    navigationOrder: `${number}`;
    recommendedSortPriority: `${number}`;
    roomSize: string;
    shipZoneImage: TImage;
    shipZoneLocation: string;
    submetas: TCabinCategorySubMeta[];
    title: string;
    viewOrTerraceImage: TImage;
    viewOrTerraceSpecs: string[];
    whatsIncluded: TCabinIncludedItem[];
  };

export type TAgentInput = {
  agencyId?: string;
  agentId: string;
  bookingChannel: string;
};

type TTravelPartyInput = {
  ageCategory: AgeCategoryEnum;
  count: number;
};

export type TCabinCategorySearchInput = {
  cabinSeqNo: number;
  isAccessible: boolean;
  travelParty?: TTravelPartyInput[];
};

export type TCabinCategoriesAvailabilityRequest = {
  accessKeys?: string[];
  agent?: TAgentInput;
  cabins: TCabinCategorySearchInput[];
  currencyCode: string;
  reservationNumber?: string;
  voyageId: string;
};

export type TCabinCategoriesAvailabilityResult = {
  availableCategories: TCabinCategory[];
  cabinSeqNo: number;
};

const GQL_QUERY_CABIN_CATEGORIES_AVAILABILITY_INNER_PAYLOAD = `{
      cabinSeqNo
      availableCategories {
        code
        name
        title
        isVip
        description
        roomSize
        maxOccupancy
        attributes
        shipZoneImage {
            alt
            src
        }
        submetas {
          code
          name
          title
          isVip
          description
          roomSize
          balconySize
          maxOccupancy
          cabinTypes {
            code
            name
            id
            isVip
            description
            roomSize
            balconySize
            lowestAvailablePrice {
              subCategoryCode
              fareClass
              totalPrice {
                amount
                discount
                currencyCode
                originalAmount
              }
            }
            fareClassContents {
              fareClass
              attributes
              totalPrice {
                amount
                discount
                currencyCode
                originalAmount
              }
            }
            isAvailable
            navigationOrder
            longDescription
            upsellDescription
            upsoldTo
          }
          lowestAvailablePrice {
            fareClass
            totalPrice {
              amount
              discount
              currencyCode
              originalAmount
            }
          }
          fareClassContents {
            fareClass
            totalPrice {
              amount
              discount
              currencyCode
              originalAmount
            }
            attributes
          }
          isAvailable
          navigationOrder
          landscapeGallery {
            alt
            src
          }
          longDescription
          id
          shipZoneImage {
            alt
            src
          }
          attributes
          bannerMessage
          maxDiscount
          floorplan {
            alt
            src
          }
          upsellDescription
          upsoldTo
        }
        lowestSubCategoryPrice {
          subCategoryCode
          fareClass
          totalPrice {
            amount
            discount
            currencyCode
            originalAmount
          }
        }
        navigationOrder
        landscapeGallery {
          alt
          src
        }
        whatsIncluded {
          text
          icon {
            alt
            src
          }
        }
        longDescription
        viewOrTerraceImage {
          alt
          src
        }
        capacityImage {
          alt
          src
        }
        smallThumbnail {
          alt
          src
        }
        headerImage {
          alt
          src
        }
        cabinServices {
          name
          icon {
            alt
            src
          }
        }
        amenities
        viewOrTerraceSpecs
        shipZoneLocation
        recommendedSortPriority
        recirculationMessage
      }
    }`;

export const CABIN_CATEGORIES_AVAILABILITY = gql`
  query CabinCategoriesAvailability($value: CabinCategoriesAvailabilityRequest!) {
    cabinCategoriesAvailability(value: $value) ${GQL_QUERY_CABIN_CATEGORIES_AVAILABILITY_INNER_PAYLOAD}
  }
`;

export const CABIN_CATEGORIES_AVAILABILITY_V2 = gql`
  query CabinCategoriesAvailabilityV2($value: CabinCategoriesAvailabilityRequest!) {
    cabinCategoriesAvailabilityV2(value: $value) ${GQL_QUERY_CABIN_CATEGORIES_AVAILABILITY_INNER_PAYLOAD}
  }
`;

export const getCabinCategoriesAvailabilityQueryDetails = (isFareClassesEnabled) => {
  return {
    query: isFareClassesEnabled ? CABIN_CATEGORIES_AVAILABILITY : CABIN_CATEGORIES_AVAILABILITY_V2,
    queryPath: isFareClassesEnabled ? 'cabinCategoriesAvailability' : 'cabinCategoriesAvailabilityV2',
  };
};
