import type { FareClassesEnum } from '@/features/fareClasses/types';
import type {
  TCabinCategoryCabinType,
  TCabinCategorySubMeta,
} from '@/helpers/api/graphql/queries/cabinCategoriesAvailability';

import { pickAnyFromSearchParams, type PickAnyFromSearchParams } from '@/ducks/pages/navigation';
import { routes } from '@/ducks/routes';
import { ChooseCategorySearchParams } from '@/ducks/voyageInfo/category/searchParams';

export const onCategoriesChoosingCompleted = ({
  fareClass,
  isUpsellApplied,
  subCategory,
}: {
  fareClass?: FareClassesEnum;
  isUpsellApplied?: boolean;
  subCategory: TCabinCategoryCabinType;
}) => {
  routes.planner.summary.go({
    keepSearch: true,

    [ChooseCategorySearchParams.chosenSubCategoryCode]: subCategory?.code,
    ...(isUpsellApplied !== undefined && { [ChooseCategorySearchParams.isUpsell]: isUpsellApplied }),
    ...(fareClass && { [ChooseCategorySearchParams.fareClass]: fareClass }),
  });
};

export const backToMainPage = (args: PickAnyFromSearchParams = {}) => {
  routes.planner.chooseVoyage.go(
    pickAnyFromSearchParams({ withCharter: true, withFilters: true, withFM: true, withMNVV: true, ...args }),
  );
};

export const toPreCheckoutPage = (args: PickAnyFromSearchParams = {}) => {
  routes.planner.preCheckOut.go(
    pickAnyFromSearchParams({
      withCelebration: true,
      withCharter: true,
      withChooseSailing: true,
      withFilters: true,
      ...args,
    }),
  );
};

export const toSubCategoryPage = (
  chosenCategoryCode: TCabinCategorySubMeta['code'],
  args: PickAnyFromSearchParams = {},
) => {
  if (!chosenCategoryCode) {
    toCategoryPage();
    return;
  }

  routes.planner.chooseCabin.category.go(
    pickAnyFromSearchParams({
      withCelebration: true,
      withCharter: true,
      withChooseSailing: true,
      withFilters: true,
      ...args,
    }),
    '',
    {
      cabinCategoryCode: chosenCategoryCode,
    },
  );
};

export const toCategoryPage = (args: PickAnyFromSearchParams = {}) => {
  routes.planner.chooseCabin.go(
    pickAnyFromSearchParams({
      withCelebration: true,
      withCharter: true,
      withChooseSailing: true,
      withFilters: true,
      ...args,
    }),
  );
};
