import type { PromoCode } from '@/ducks/accessKeys/type';
import type { FetchCabinsRequestRedux } from '@/ducks/cabinsCommon/types';
import type { TCurrencyCode } from '@/infra/types/common';
import type { TAgencyDetails, TAgentDetails } from '@/infra/types/fmlink/agent';

import { selectAccessKeyPromoCode } from '@/ducks/accessKeys';
import { selectAccessible, selectCurrencyCode, selectSailors } from '@/ducks/filters/selectors';
import { selectAgencyDetails, selectAgentDetails } from '@/ducks/fm/selectors';
import { selectMnvvReservationNumber } from '@/ducks/mnvv/selectors';
import { AgeCategoryEnum } from '@/helpers/api/graphql/queries/cabinCategoriesAvailability';
import createMemoSelector from '@/helpers/createMemoSelector';

export const selectPayloadForCabinsFetch = createMemoSelector(
  [
    selectAccessible,
    selectCurrencyCode,
    selectSailors,
    selectAccessKeyPromoCode,
    selectAgentDetails,
    selectAgencyDetails,
    selectMnvvReservationNumber,
  ],
  (
    isAccessible: boolean,
    currencyCode: TCurrencyCode,
    sailors: number,
    promoCode?: PromoCode,
    agentDetails?: TAgentDetails,
    agencyDetails?: TAgencyDetails,
    reservationNumber?: string,
  ): FetchCabinsRequestRedux => ({
    accessKeys: promoCode ? [promoCode] : [],
    cabins: [
      {
        cabinSeqNo: 1,
        isAccessible,
        travelParty: [{ ageCategory: AgeCategoryEnum.ADULT, count: sailors }],
      },
    ],
    currencyCode,
    ...(agentDetails
      ? {
          agent: {
            agencyId: agencyDetails?.agencyId,
            agentId: agentDetails.agentId,
            bookingChannel: agentDetails.bookingChannel,
          },
        }
      : {}),
    reservationNumber,
  }),
);
