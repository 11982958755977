import type {
  TCabinCategory,
  TCabinCategoryCabinType,
} from '@/helpers/api/graphql/queries/cabinCategoriesAvailability';
import type { AppDispatch } from '@/store';

import { clearInvoiceDataAction } from '@/actions';
import { clearChosenCategory, setChosenCategory } from '@/ducks/voyageInfo/category';
import { clearChosenSubCategory, setChosenSubCategory } from '@/ducks/voyageInfo/subCategory';
import { setPaymentBookNowCallStatus } from '@/helpers/managers/payment';

export const chooseSubCategory = (code: TCabinCategoryCabinType['code']) => (dispatch: AppDispatch) => {
  setPaymentBookNowCallStatus(false);

  if (code) {
    dispatch(setChosenSubCategory(code));
    return;
  }

  dispatch(clearChoseCategoriesAndInvoice());
};

export const chooseCategory = (code: TCabinCategory['code']) => (dispatch: AppDispatch) => {
  dispatch(clearChoseCategoriesAndInvoice());

  dispatch(setChosenCategory(code));
};

export const clearChoseCategoriesAndInvoice = () => (dispatch: AppDispatch) => {
  dispatch(clearInvoiceDataAction());

  dispatch(clearChoseCategories());
};

export const clearChoseCategories = () => (dispatch: AppDispatch) => {
  dispatch(clearChosenSubCategory());
  dispatch(clearChosenCategory());
};
