export type TApiErrorData = {
  code: string;
  message: string;
};

export const enum BookNowFailureCode {
  CABIN_EXHAUSTED = '0113323',
  GENERAL = '0111322',
  FORBIDDEN = 'FORBIDDEN',
}

export const enum CalculateInvoiceFailureCode {
  SEAWEAR_SAILOR_NOT_PERMITTED = '013323',
  SEAWEAR_SAILOR_WRONG_CAPACITY = '0113323',
  FORBIDDEN = 'FORBIDDEN',
}

export type TOnResponseHeaders = (headers: Headers) => void;

export type TWithResponseHeaders = {
  onResponseHeaders?: TOnResponseHeaders;
};
