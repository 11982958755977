import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import {
  ACCOUNTS_DASHBOARD_DATA_ERROR,
  ACCOUNTS_DASHBOARD_DATA_SUCCESS,
  CLEAR_LOGGED_IN_SESSION_DATA,
} from '@/constants/actionTypes';
import { routes } from '@/ducks/routes';
import { getSearchParams } from '@/ducks/routes/history';
import { backToMainPage } from '@/ducks/voyageInfo/category/navigation';
import { fetchDashboardData } from '@/helpers/api/app';
import { getAuthFromStorage } from '@/helpers/auth/core';
import { getSessionStorageValue } from '@/helpers/util/storage';

export const selectPaymentEncReservationNumber = (state) => state?.voyagePlanner?.payment?.encReservationNumber?.data;

export const clearLoggedInSessionData = () => (dispatch) => {
  dispatch({
    payload: {},
    type: CLEAR_LOGGED_IN_SESSION_DATA,
  });
};

export function fetchDashboardDataAction(reservationNumber, processingId) {
  return async (dispatch, getState) => {
    let data = [];
    try {
      const state = getState();
      const isLoggedIn = !!getAuthFromStorage();
      const finalReservationNumber =
        (!isLoggedIn && (selectPaymentEncReservationNumber(state) || getSessionStorageValue('encResId'))) ||
        getSearchParams()?.reservationNumber ||
        reservationNumber;
      data = await fetchDashboardData(finalReservationNumber, processingId);
      if (isEmpty(get(data, '0.reservationNumber'))) {
        backToMainPage({
          withCelebration: false,
          withChooseCabin: false,
          withChooseSailing: false,
          withFilters: false,
        });
      }
      dispatch({
        payload: data,
        type: ACCOUNTS_DASHBOARD_DATA_SUCCESS,
      });
    } catch (err) {
      dispatch({
        payload: null,
        type: ACCOUNTS_DASHBOARD_DATA_ERROR,
      });
      routes.page404.go();
    }
    return data;
  };
}
