import { PAYMENT_SET_TRANSACTION_DATA } from '@/constants/actionTypes';
import { removeActiveAccessKeyAction } from '@/ducks/accessKeys';
import { getSearchParamsObjectWithoutAccessKey } from '@/ducks/accessKeys/utils';
import { routes } from '@/ducks/routes';
import { setCompletedFlow } from '@/ducks/travelParty/slice';

export const onContinueToConfirmationAction = (isLoggedIn, transactionData) => (dispatch) => {
  dispatch({
    payload: transactionData,
    type: PAYMENT_SET_TRANSACTION_DATA,
  });

  dispatch(setCompletedFlow());
  dispatch(removeActiveAccessKeyAction());
  const searchWithOutAccessKey = getSearchParamsObjectWithoutAccessKey();
  if (isLoggedIn) {
    routes.planner.confirmation.replace(searchWithOutAccessKey);
  } else {
    routes.planner.payment.connect.go(searchWithOutAccessKey);
  }
};

export default onContinueToConfirmationAction;
