import { createSelector } from '@reduxjs/toolkit';

import type { TCabinCategory } from '@/helpers/api/graphql/queries/cabinCategoriesAvailability';
import type { RootState } from '@/store';

import { selectCabinCategories } from '@/reducers/voyagePlanner/chooseCabin/cabinCategories/selectors';

const selectSelf = (state: RootState) => state.voyageInfo.chosenCategory;

export const selectChosenCategory = createSelector([selectSelf, selectCabinCategories], (common, cabinCategories) =>
  cabinCategories.find((category: TCabinCategory) => category.code === common.id),
);
