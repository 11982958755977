import { createSelector } from '@reduxjs/toolkit';

import type { TCabinCategory, TCabinCategorySubMeta } from '@/helpers/api/graphql/queries/cabinCategoriesAvailability';
import type { TCodifiedName } from '@/infra/types/common';
import type { RootState } from '@/store';

import createMemoSelector from '@/helpers/createMemoSelector';

const selectChooseCabin = (state: RootState) => state?.voyagePlanner?.chooseCabin;

export const selectChooseCabinCategories = (state: RootState) => selectChooseCabin(state)?.cabinCategories;

export const selectChooseCabinCategoriesActualData = (state: RootState) =>
  selectChooseCabinCategories(state)?.actualData;

export const selectChooseCabinCategoriesData = (state: RootState) => selectChooseCabinCategories(state)?.data;
export const selectCabinCategory = createSelector(
  [selectChooseCabinCategoriesData, (_, cabinCategoryCode) => cabinCategoryCode],
  (cabinCategories, cabinCategoryCode) => {
    return cabinCategories.find((cc) => cc.code === cabinCategoryCode);
  },
);

export const selectChooseCabinCategoriesApiPayload = (state: RootState) =>
  selectChooseCabinCategories(state)?.apiPayload;

export const selectCabinNamesByCodesMap = createMemoSelector(
  selectChooseCabinCategoriesActualData,
  (actualData): Record<string, string> => {
    const append = (map: Record<string, string>, item: TCodifiedName) => {
      if (item?.code && item?.name) {
        map[item.code] = item.name;
      }
    };
    return actualData?.reduce(
      (acc: Record<string, string>, category: TCabinCategory) => {
        category?.submetas.forEach((submeta: TCabinCategorySubMeta) => {
          submeta?.cabinTypes?.forEach((item) => append(acc, item));
          append(acc, submeta);
        });
        append(acc, category);
        return acc;
      },
      actualData?.length ? {} : undefined,
    );
  },
);
