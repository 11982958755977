import type { TCabinCategory } from '@/helpers/api/graphql/queries/cabinCategoriesAvailability';
import type { HttpError } from '@/helpers/api/utils';

import { fetchCabinCategories } from '@/ducks/cabinsCommon/cabinCategories/api';
import { selectPayloadForCabinsFetch } from '@/ducks/cabinsCommon/selectors';
import { clearChoseCategoriesAndInvoice } from '@/ducks/voyageInfo/category/actions';
import { backToMainPage, toCategoryPage, toPreCheckoutPage } from '@/ducks/voyageInfo/category/navigation';
import {
  setWarningAnyCabinsExhausted,
  setWarningCabinCategoryExhausted,
  setWarningPreCheckoutBookNowFail,
} from '@/features/warnings/actions';
import getCurrentSearchParams from '@/helpers/url/getCurrentSearchParams';
import { isVWOFeatureEnabled } from '@/helpers/vwo/isFeatureEnabled';
import { BookNowFailureCode, type TApiErrorData } from '@/infra/types/api/common';
import { rootStore } from '@/store';
import { type TOptional } from '@/types/common';
import { VWOFlag } from '@/types/vwo';

const isEquivalentInTokens = (pattern: string, value: TOptional<string>): TOptional<boolean> => {
  if (value && pattern) {
    const reTokens = /[^a-z0-9]+/gi;
    const [a, b] = [value, pattern].map((str) => str.replace(reTokens, ' ').trim().toLowerCase());
    return a!.startsWith(b!);
  }
};

const CATCHABLE_EXHAUST_ERROR = {
  code: BookNowFailureCode.CABIN_EXHAUSTED,
  message: 'Seaware Error: 323: The booking is waitlisted for one or more Selling limit',
};

const FORBIDDEN_ERROR = {
  code: BookNowFailureCode.FORBIDDEN,
};

const bookNowExhaustCatcher = async (error: HttpError): Promise<TOptional<boolean>> => {
  const data = error.responseBody;
  const { status } = error?.response ?? ({} as Response);
  if (status < 400) return;

  const { dispatch, getState } = rootStore!;
  const state = getState();

  const { code, message } = (data ?? {}) as TApiErrorData;
  if (CATCHABLE_EXHAUST_ERROR.code === code || isEquivalentInTokens(CATCHABLE_EXHAUST_ERROR.message, message)) {
    const searchParams = getCurrentSearchParams();
    const payload = selectPayloadForCabinsFetch(state);
    const isFeatureFareClassesEnabled = await isVWOFeatureEnabled(VWOFlag.FARE_CLASSES);
    const voyageId = searchParams.get('voyageId')!;

    let cabinCategories;
    try {
      cabinCategories = await fetchCabinCategories({ ...payload, voyageId }, isFeatureFareClassesEnabled);
    } catch {
      /* nothing */
    }
    const hasAnyCabins = (cabinCategories?.data as TCabinCategory[])?.some(
      (cabinCategory: TCabinCategory) => cabinCategory?.isAvailable,
    );
    if (hasAnyCabins) {
      dispatch(setWarningCabinCategoryExhausted(true));
      dispatch(clearChoseCategoriesAndInvoice());
      toCategoryPage();
    } else {
      dispatch(setWarningAnyCabinsExhausted());

      backToMainPage({ withCharter: true, withFilters: true });
    }
    return true;
  }

  if (FORBIDDEN_ERROR.code === code) {
    dispatch(setWarningPreCheckoutBookNowFail());
    toPreCheckoutPage();
    return true;
  }
};

export default bookNowExhaustCatcher;
