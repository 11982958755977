export const enum WarningType {
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
}

export type TWarning = {
  text: string;
  title: string;
  type: WarningType;
};

export const enum WarningTarget {
  preCheckOut = 'preCheckOut',
  chooseCabinMeta = 'chooseCabinMeta',
  chooseVoyage = 'chooseVoyage',
  preCheckoutTop = 'preCheckoutTop',
  preCheckoutSecondary = 'preCheckoutSecondary',
}
