import { selectCountryCode, selectLookup, selectStateCode } from '@/ducks/common/selectors';
import { EmailOptIn, FIELD_NAMES } from '@/ducks/travelParty/constants';
import { selectPrevSubscriptionsPrimarySailor } from '@/ducks/travelParty/selectors';
import { setPrevSubscriptions } from '@/ducks/travelParty/slice';
import { subscribe } from '@/features/api/endpoints/user';
import { selectProfileEmailNotifications } from '@/features/profile/selectors';
import { getCountryDialCode } from '@/helpers/util';
import { isVWOFeatureEnabled } from '@/helpers/vwo/isFeatureEnabled';
import { VWOFlag } from '@/types/vwo';

export const subscribeForNewsAction = (primarySailor, subscriptionSource) => async (dispatch, getState) => {
  const {
    add_newsletter: addNewsletter,
    contactnumber: phoneNumber,
    dob: dateOfBirth,
    email,
    firstname: firstName,
    isOptInForEmailRadio,
    isOptInForSMS,
    lastname: lastName,
    phoneCountryCode,
  } = primarySailor || {};
  const state = getState();
  const notifications = selectProfileEmailNotifications(state);
  const prevSubscriptions = selectPrevSubscriptionsPrimarySailor(state);
  const lookup = selectLookup(state);
  const isEmailOptInEnabled = await isVWOFeatureEnabled(VWOFlag.EMAIL_OPT_IN_RADIO);
  const canSkip =
    !email ||
    (isEmailOptInEnabled && isOptInForEmailRadio === undefined && notifications?.every((item) => item.IsOptInForEmail));

  if (canSkip) return;

  const isSailorChangedOpinionForSubscriptions = getIsSailorChangedOpinionForSubscriptions(
    prevSubscriptions,
    primarySailor,
  );

  const isSailorSubscribedFirstTime = getIsSailorSubscribedFirstTime(prevSubscriptions, primarySailor);

  if (isSailorSubscribedFirstTime || isSailorChangedOpinionForSubscriptions) {
    const isOptInForEmailResult = addNewsletter || false;
    const isOptInForSMSResult = isOptInForSMS || false;

    dispatch(
      setPrevSubscriptions({
        [FIELD_NAMES.IS_OPT_IN_FOR_EMAIL]: isOptInForEmailResult,
        [FIELD_NAMES.IS_OPT_IN_FOR_EMAIL_RADIO]: isOptInForEmailRadio === EmailOptIn.YES,
        [FIELD_NAMES.IS_OPT_IN_FOR_SMS]: isOptInForSMSResult,
      }),
    );

    return subscribe({
      ...subscriptionSource,
      dateOfBirth,
      email,
      firstName,
      isOptInForEmail: isOptInForEmailResult || isOptInForEmailRadio === EmailOptIn.YES,
      isOptInForSMS: isOptInForSMSResult,
      lastName,
      notificationTypeCode: 'NO',
      phoneCountryCode: getCountryDialCode(lookup, phoneCountryCode),
      phoneNumber,
      regionCode: selectCountryCode(state),
      stateCode: selectStateCode(state),
    });
  }
};

const getIsSailorChangedOpinionForSubscriptions = (prevSubscriptions, primarySailor) =>
  prevSubscriptions
    ? prevSubscriptions[FIELD_NAMES.IS_OPT_IN_FOR_EMAIL] !==
        Boolean(primarySailor?.[FIELD_NAMES.IS_OPT_IN_FOR_EMAIL]) ||
      prevSubscriptions[FIELD_NAMES.IS_OPT_IN_FOR_SMS] !== Boolean(primarySailor?.[FIELD_NAMES.IS_OPT_IN_FOR_SMS]) ||
      prevSubscriptions[FIELD_NAMES.IS_OPT_IN_FOR_EMAIL_RADIO] !==
        (primarySailor?.[FIELD_NAMES.IS_OPT_IN_FOR_EMAIL_RADIO] === EmailOptIn.YES)
    : false;

const getIsSailorSubscribedFirstTime = (prevSubscriptions, primarySailor) =>
  prevSubscriptions
    ? false
    : primarySailor[FIELD_NAMES.IS_OPT_IN_FOR_EMAIL] ||
      primarySailor[FIELD_NAMES.IS_OPT_IN_FOR_SMS] ||
      primarySailor[FIELD_NAMES.IS_OPT_IN_FOR_EMAIL_RADIO] === EmailOptIn.YES;

export default subscribeForNewsAction;
