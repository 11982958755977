import { makeUIResourcePoolSelector, type TUIResourceId } from '@/ducks/common/resources';
import { type AppDispatch, type AppGetState } from '@/store';

import { setWarning } from './slice';
import { WarningTarget, WarningType } from './types';

export { clearAll, clearWarning, setWarning } from './slice';

export const setWarningPreCheckoutCalcInvoiceFail = (asError?: boolean) =>
  setExhaustedWarning([WarningTarget.preCheckoutSecondary], asError ? WarningType.ERROR : WarningType.WARNING, {
    text: 'MismatchError.description',
    title: 'MismatchError.heading',
  } as const);

export const setWarningPreCheckoutBookNowFail = (asError?: boolean) =>
  setExhaustedWarning([WarningTarget.preCheckoutSecondary], asError ? WarningType.ERROR : WarningType.WARNING, {
    text: 'MismatchError.description',
    title: 'MismatchError.heading',
  } as const);

export const setWarningCabinCategoryExhausted = (asError?: boolean) =>
  setExhaustedWarning([WarningTarget.chooseCabinMeta], asError ? WarningType.ERROR : WarningType.WARNING, {
    text: 'error.ChooseCabinRedirect.body',
    title: 'error.ChooseCabinRedirect.heading',
  } as const);

export const setWarningAnyCabinsExhausted = () =>
  setExhaustedWarning([WarningTarget.chooseVoyage, WarningTarget.preCheckoutTop], WarningType.ERROR, {
    text: 'error.ChooseCabinRedirect.body',
    title: 'error.ChooseCabinRedirect.heading',
  } as const);

export const setWarningCategoriesFetchError = () =>
  setExhaustedWarning([WarningTarget.chooseVoyage, WarningTarget.preCheckoutTop], WarningType.ERROR, {
    text: 'Error.missingSearchRequest.body',
    title: 'Error.missingSearchRequest.heading',
  } as const);

export const setWarningForPastVoyage = (asError?: boolean) =>
  setExhaustedWarning(
    [WarningTarget.chooseVoyage, WarningTarget.preCheckoutTop],
    asError ? WarningType.ERROR : WarningType.WARNING,
    {
      text: 'PastVoyageError.body',
      title: 'PastVoyageError.heading',
    } as const,
  );

export const setEmptySailingDataWarningExhausted = (asError?: boolean) =>
  setExhaustedWarning([WarningTarget.chooseVoyage], asError ? WarningType.ERROR : WarningType.WARNING, {
    text: 'Error.emptyDetails.heading',
    title: 'Error.emptyDetails.subheading',
  } as const);

export const setExhaustedWarning =
  (targets: WarningTarget[], type: WarningType, uiResourcePool: Record<'text' | 'title', TUIResourceId>) =>
  (dispatch: AppDispatch, getState: AppGetState) => {
    const selector = makeUIResourcePoolSelector(uiResourcePool);
    const { text, title } = selector(getState());
    dispatch(setWarning({ targets, warning: { text, title, type } }));
  };
