import { BOOK_NOW_ENCRYPTED_RESERVATION_SUCCESS, SAILOR_DETAILS_BOOK_NOW_SUCCESS } from '@/constants/actionTypes';
import { removePaymentPaymentOption, removePaymentReservationNumber } from '@/helpers/managers/payment';

// Clear up any data which could stay in redux / session after previous bookings
export const clearVoyagePlannerData = () => (dispatch) => {
  dispatch({
    payload: '',
    type: BOOK_NOW_ENCRYPTED_RESERVATION_SUCCESS,
  });
  dispatch({
    payload: [],
    type: SAILOR_DETAILS_BOOK_NOW_SUCCESS,
  });

  removePaymentReservationNumber();
  removePaymentPaymentOption();
};
