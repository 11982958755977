import { createSelector } from '@reduxjs/toolkit';

import { findCabinCategory } from '@/ducks/pages/chooseCabin/helpers/findCabinCategory';
import { selectChooseCabinCategories } from '@/ducks/pages/chooseCabin/selectors';

export const selectCabinCategories = createSelector(selectChooseCabinCategories, (slice) => slice?.data);

export const selectIsCabinCategoriesLoaded = createSelector(selectChooseCabinCategories, (slice) => slice?.isLoaded);

export const selectIsCabinCategoriesError = createSelector(selectChooseCabinCategories, (slice) => slice?.error);

export const selectIsCabinCategoriesIsLoading = createSelector(
  selectChooseCabinCategories,
  (slice) => slice?.isLoading,
);

export const selectCabinCategoryBySubCategoryCode = createSelector(
  [selectCabinCategories, (_, cabinTypeCode) => cabinTypeCode],
  (cabinCategories, cabinTypeCode) => findCabinCategory(cabinCategories, cabinTypeCode),
);

export const selectSeatMapVrIsLoaded = createSelector(selectChooseCabinCategories, (slice) => slice?.isSeatMapVrLoaded);
